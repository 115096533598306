<template>
  <div class="g_page_box" style="background: #f7f7f7">
    <div class="mock">
      <van-form validate-first @submit="onSubmit" v-if="autoMockSend !== true">
        <!-- 通过 validator 进行函数校验 -->
        <van-field
          v-model="simulation.no"
          v-if="source !== 6"
          name="validator"
          placeholder="请输入电话号码"
          :rules="[{ validator, message: '请输入正确的电话号码' }]"
        />
        <van-field
          v-if="source === 5"
          v-model.trim="simulation.user_name"
          name="user_name"
          placeholder="请输入健康卡绑定姓名"
          :rules="[{ required: true, message: '请输入健康卡绑定姓名' }]"
        />
        <van-field
          v-if="[6, 8].includes(source)"
          v-model.trim="simulation.patient_id"
          name="patient_id"
          placeholder="请输入卡号"
          :rules="[{ required: true, message: '请输入卡号' }]"
        />
        <div class="btn_box">
          <van-button type="info" native-type="submit" block
            >发送文章</van-button
          >
        </div>
      </van-form>

      <van-button type="info" block @click="close_window">关闭页面</van-button>
      <h3 class="title2" v-if="success">消息已发送，请注意微信推送</h3>
      <h3 class="title">扫码关注公众号并绑卡</h3>
      <div class="img_box" v-if="[3, 6, 7].includes(source)">
        <img
          width="220"
          height="auto"
          v-if="source === 3"
          :src="`./img/wfy-qrcode.jpg`"
        />
        <img
          width="220"
          height="auto"
          v-if="source === 6"
          :src="`./img/ch-qrcode.jpeg`"
        />
        <img
          width="220"
          height="auto"
          v-if="source === 7"
          :src="`./img/wzzx-qrcode.jpeg`"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { to_simulation, sendBy3th } from "./service"
import { get_url_params } from "../../utils/format"

export default {
  data() {
    return {
      simulation: {
        no: "",
        user_name: "",
        patient_id: "",
      },
      success: false,
      source: GLOBAL_CONFIG.source,
      autoMockSend: GLOBAL_CONFIG.autoMockSend,
    }
  },
  created() {
    this.get3thCode()
  },
  methods: {
    async get3thCode() {
      if (this.autoMockSend !== true) return
      //wx 登录
      const query = {
        ...get_url_params(location.href.split("#")[0]),
        ...(this.$route.query || {}),
      }
      if (query.code) {
        const { plan_type, plan_id } = query
        // 推送
        const { data } = await sendBy3th({
          plan_id: +plan_id,
          plan_type: +plan_type,
          vx_code: query.code,
        })
        console.log(data)
        this.success = true
        return
      }
      if (!query.code) {
        const redirect_uri = encodeURIComponent(
          `${
            GLOBAL_CONFIG.url3th
          }/#/third-redirect?redirect_uri=${encodeURIComponent(location.href)}`
        )
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${GLOBAL_CONFIG.appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=123&component_appid=${GLOBAL_CONFIG.component_appid}#wechat_redirect`
      }
    },
    validator(val) {
      return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
        val
      )
    },
    onSubmit() {
      this.to_simulation()
    },
    async to_simulation() {
      try {
        this.success = false
        const { plan_type, plan_id } = this.$route.query
        let params = {
          ...this.simulation,
          plan_id: +plan_id,
          plan_type: +plan_type,
        }
        await to_simulation(params)
      } finally {
        this.success = true
      }
    },
    close_window() {
      if (typeof WeixinJSBridge === undefined) return

      setTimeout(() => {
        document.addEventListener(
          "WeixinJSBridgeReady",
          function () {
            WeixinJSBridge.call("closeWindow")
          },
          false
        )
        WeixinJSBridge.call("closeWindow")
      }, 500)
    },
  },
}
</script>
<style scoped>
.mock {
  padding: 12px;
}

.btn_box {
  padding: 16px 0;
}

.title {
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  padding: 50px 0 20px;
}

.img_box {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title2 {
  font-weight: 500;
  text-align: center;
  padding-top: 20px;
}
</style>
